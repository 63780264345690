export default `
mutation createPartnerEnquiry($input:createPartnerEnquiryInput!) { 
    createPartnerEnquiry(input:$input){ 
        partnerEnquiry{
        name
        email
        }
    }
    }
`;
