import React, { useState, useRef } from "react";
import { getValue } from "../../utils/lodash";
import RequestCallbackModal from "./request-callback";
import SimpleReactValidator from "simple-react-validator";
import { Link, NavLink } from "react-router-dom";
import fetchCreatePartnerEnquirie from "../../services/createPartnerEnquirie/fetchCreatePartnerEnquirie";
import { toast } from "react-toastify";
import { removeNullOrUndefinedProperties } from "../../common/text-helpers";
import "./landing.scss";
import Facebooksvg from "../../assets/images/footerIcons/facebook.svg";
import Instagramsvg from "../../assets/images/footerIcons/instagram.svg";
import Youtubesvg from "../../assets/images/footerIcons/youtube.svg";
import Pinterest from "../../assets/images/footerIcons/pinterest.png";
import LinkedinSVG from "../../assets/images/footerIcons/linkedin.svg";
import Thread from "../../assets/images/footerIcons/thread.svg";
import Twitter from "../../assets/images/footerIcons/twitter.svg";
import Ticon from "../../assets/images/footerIcons/t-icon.png";
import Footer from "../../components/footer";

const smartClinicFeature = [
  {
    icon: "/images/homepage/article.svg",
    alt: "article-icon",
    highlight: "Instant reports",
  },
  {
    icon: "/images/homepage/medical_services.svg",
    alt: "medical-services-icon",
    highlight: "Comprehensive health assessments",
  },
  {
    icon: "/images/homepage/cast.svg",
    highlight: "General Practitioner (GP) tele-consultations",
    alt: "cast-icon",
  },
];

const keyFeatures = [
  {
    icon: "/images/homepage/blood-test.png",
    alt: "blood-test-icon",
    highlight: "Online Booking of Tests",
  },
  {
    icon: "/images/homepage/medical-report.png",
    alt: "medical-report-icon",
    highlight: "General Health Check-Up Packages",
  },
  {
    icon: "/images/homepage/blood.png",
    alt: "blood-drops-icon",
    highlight: "Diabetic Check-Up Packages",
  },
  {
    icon: "/images/homepage/blood-sample.png",
    alt: "blood-sample-icon",
    highlight: "HbA1c Medical Testing",
  },
  {
    icon: "/images/homepage/resume.png",
    alt: "resume-icon",
    highlight: "Lipid Profile Testing",
  },
  {
    icon: "/images/homepage/heart.png",
    alt: "heart-icon",
    highlight: "Cardiac Check-Up (ECG)",
  },
  {
    icon: "/images/homepage/video-lesson.png",
    alt: "video-lesson-icon",
    highlight: "Tele-Consultation with Doctors ",
  },
  {
    icon: "/images/homepage/document.png",
    alt: "document-icon",
    highlight: "Instant Report Delivery via Email, WhatsApp, or Print",
  },
];

const wlProgram = [
  {
    icon: "/images/homepage/directions_walk.svg",
    alt: "directions_walk-icon",
    highlight: "Steps",
  },
  {
    icon: "/images/homepage/network_check.svg",
    alt: "network-check-icon",
    highlight: "Calories",
  },
  {
    icon: "/images/homepage/water_drop.svg",
    alt: "water-drop-icon",
    highlight: "Water Intake",
  },
  {
    icon: "/images/homepage/airline_seat.svg",
    alt: "airline-seat-icon",
    highlight: "Sleep",
  },
  {
    icon: "/images/homepage/fastfood.svg",
    alt: "fastfood-icon",
    highlight: "Diet Compliance",
  },
  {
    icon: "/images/homepage/accessibility.svg",
    alt: "accessibility-icon",
    highlight: "Progress",
  },
  {
    icon: "/images/homepage/growth.svg",
    alt: "growth-icon",
    highlight: "Measurements",
  },
  {
    icon: "/images/homepage/shield.svg",
    alt: "shield-icon",
    highlight: "Body Composition Analysis (BCA)",
  },
  {
    icon: "/images/homepage/bloodtype.svg",
    alt: "bloodtype-icon",
    highlight: "Blood Parameters",
  },
  {
    icon: "/images/homepage/devices.svg",
    alt: "devices-icon",
    highlight:
      "The app integrates with Fitbit, Apple Health, and Google Fit, ensuring a comprehensive approach to health and fitness.",
  },
];

const companyImages = [
  { image: "/images/homepage/redcliff-labs.png", alt: "redcliff-labs-logo" },
  {
    image: "/images/homepage/healthians.png",
    alt: "healthians-logo",
  },
  {
    image: "/images/homepage/thyrocare.png",
    alt: "thyrocare-logo",
  },
  {
    image: "/images/homepage/apollo-logo.png",
    alt: "apollo-logo",
  },
  {
    image: "/images/homepage/aarthi-logo.png",
    alt: "aarthi-logo",
  },
  {
    image: "/images/homepage/nuclear-logo.png",
    alt: "nuclear-logo",
  },
];

const additionalServices = [
  {
    image: "/images/homepage/online-service-1.webp",
    alt: "tablets",
  },
  {
    image: "/images/homepage/online-service-2.webp",
    alt: "doctor",
  },
  {
    image: "/images/homepage/online-service-3.webp",
    alt: "pregnant-lady",
  },
];

const ourOffers = [
  {
    heading: "Fully Automatic Health ATM",
  },
  {
    heading: "HT Weight Loss Clinic",
  },
  {
    heading: "Lab Tests",
  },
  {
    heading:
      "Online Booking Management and Listing of Your Location in Our Booking Engine",
  },
  {
    heading: "Branding of the Premises",
  },
  {
    heading: "6 Months of Social Media and WhatsApp Marketing Support",
  },
  {
    heading: "Sponsorship of Local Events in Your Area",
  },
  {
    heading: "Training for Your Staff",
  },
  {
    heading: "After-Sales Service for Your Machine",
  },
  {
    heading: "Online Doctor Consultations via the Machine",
  },
  {
    heading: "Supply of Raw Materials on a Cost Basis",
  },
];

function HomeBookNow(props) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const data = [
    "General Body Check-ups",
    "Cardiac Check-ups",
    "Diabetic Check-ups",
    "Rapid Diagnostic Check-ups",
    "GP Tele-Consultation",
    "Generate Instant Accurate Reports on the spot",
  ];
  const data1 = [
    "Instant Reports",
    "Comprehensive Health Assessments",
    "GP Tele-Consultation",
  ];
  const data2 = [
    {
      title: "Proven Success",
      desc: "Healthtunnel, with its three years of successful operations and a substantial user base, brings a track record of success and reliability.",
    },
    {
      title: "Technology Integration",
      desc: "The fusion of Technology-Driven health assessments with trained medical staff ensures a perfect blend of efficiency and expertise.",
    },
    {
      title: "Growing Healthcare Market",
      desc: "As the demand for accessible and instant healthcare solutions continues to rise, HT Smart Clinics address a significant market need.",
    },
    {
      title: "Brand Recognition",
      desc: "Franchisees will benefit from the established brand recognition of Healthtunnel, Providing a head start in the competitive healthcare industry.",
    },
  ];

  const [activeTab, setActiveTab] = useState(1);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [request, setRequest] = useState({
    name: "",
    phone: "",
    email: "",
    comments: "",
  });
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitModal = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let resp = await fetchCreatePartnerEnquirie(
          removeNullOrUndefinedProperties(request)
        );
        if (resp) {
          toast.success("Submitted successfully");
          setSubmitLoading(false);
          setRequest({
            ...request,
            name: "",
            phone: "",
            email: "",
            comments: "",
          });
          toggle();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  return (
    <>
      <section className="new-landing-wrapper">
        <div className="banner-container">
          <div className="banner-text-container">
            <h2 className="banner-text">
              Launch a<br /> <span className="text-blue">Healthtunnel</span>{" "}
              Franchise
              <br /> in Your Town!
            </h2>
            <p className="banner-description mt-3 mb-4">
              Transform Your Healthcare Business with Healthtunnel SMART
              CLINICS. If you are a doctor or involved in the healthcare
              industry and have a prominent location with at least 200 sq. feet
              of space, Healthtunnel is here to help you establish a
              Healthtunnel Preventive SMART CLINIC under our renowned brand,
              boosting your business.
            </p>
            <button className="banner-cta" onClick={toggle}>
              <span className="text-one">Connect for</span>
              <span className="text-two">Enquiry Now</span>
            </button>
            <img
              src="/images/homepage/home-banner.webp"
              alt="banner"
              className="mobile-banner mt-5"
            />
          </div>
        </div>
      </section>
      <section className="features-section ">
        <div className="custom-container pb-5">
          <div className="row adjust-padding">
            <div className="col-lg-6 pb-5 pb-lg-0">
              <img src="/images/homepage/atm.webp" className="feature-atm" />
            </div>
            <div className="col-lg-6">
              <h3 className="feature-heading">Introducing HT Smart Clinics</h3>
              <p className="feature-description my-3">
                HT Smart Clinics represent a revolutionary chain of healthcare
                facilities aimed at transforming health assessments . Staffed by
                trained medical personnel, these clinics feature automated
                health check-up kiosks that cover an extensive 64 parameters in
                a single visit. Key differentiators include:
              </p>
              {smartClinicFeature.map((feature, index) => (
                <div key={index} className="feature-bullets">
                  <img src={feature.icon} alt={feature.alt} />
                  <span className="feature-bullet-text">
                    {feature.highlight}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="">
            <h3 className="feature-heading text-center">
              Key Features and Services
            </h3>
            <div className="key-feature-container mt-4">
              {keyFeatures.map((feature, index) => (
                <div key={index} className="key-feature-card">
                  <img
                    src={feature.icon}
                    alt={feature.alt}
                    className="key-feature-img"
                  />
                  <span className="key-feature-text">{feature.highlight}</span>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center mt-1 py-5">
              <button className="cta-button" onClick={toggle}>
                Enquire Now
                <img src="/images/homepage/arrow_forward.svg" className="" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-container py-5">
        <div className="row adjust-padding pt-5 d-flex flex-row-reverse">
          <div className="col-lg-6 pb-5 pb-lg-0">
            <img
              src="/images/homepage/mobile-app.webp"
              className="feature-atm ms-lg-5"
            />
          </div>
          <div className="col-lg-6">
            <h3 className="feature-heading">
              Promote Wellness with Weight Loss Programs
            </h3>
            <p className="feature-description my-3">
              Health tunnel’s health and fitness app offers personalized smart
              meal plans curated by expert nutritionists and customized workout
              plans guided by certified fitness coaches. The app tracks:
            </p>
            {wlProgram.map((feature, index) => (
              <div key={index} className="feature-bullets">
                <img src={feature.icon} alt={feature.alt} />
                <span className="feature-bullet-text">{feature.highlight}</span>
              </div>
            ))}
            <div className="d-flex  mt-1 py-5">
              <button className="cta-button" onClick={toggle}>
                Enquire Now
                <img src="/images/homepage/arrow_forward.svg" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="features-section py-5">
        <div className="custom-container py-5 row">
          <div className="col-lg-6 pb-5 lg-pb-0">
            <div className=" company-logo-wrapper">
              {companyImages.map((comapany, index) => (
                <div key={index} className="comapany-logo-card">
                  <img src={comapany.image} alt={comapany.alt} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <h3 className="feature-heading">
              Book Lab Tests from Leading Companies
            </h3>
            <p className="feature-description my-3">
              Healthtunnel enables online booking of lab tests from leading and
              trusted labs in India, including Thyrocare, Tata 1MG, and
              Redcliffe Labs. We provide detailed comparisons of diagnostic test
              prices and services, allowing consumers to make informed decisions
              based on cost, service quality, and convenience. Partnered labs
              offer home sample collection services, ensuring a hassle-free and
              safe testing process, especially in the current health climate.
              Healthtunnel guarantees the best possible prices for required
              diagnostic tests.
            </p>
          </div>
          <div className="mt-5">
            <h3 className="feature-heading text-center">
              Additional Online Services
            </h3>
            <p className="feature-description mt-3 mb-5 text-center">
              Minimum Investment, Maximum Returns
            </p>
            <div className="additional-feature-wrapper mt-3">
              {additionalServices.map((service, index) => (
                <button
                  key={index}
                  className="additional-feature-card"
                  onClick={toggle}
                >
                  <img src={service.image} alt={service.alt} className="" />
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="custom-container py-5">
        <h3 className="feature-heading text-center pt-5">What We Offer</h3>
        <div className="d-flex justify-content-center">
          <p className="feature-description mt-3 mb-5 text-center w-75">
            Join Healthtunnel and bring cutting-edge preventive healthcare
            services to your community while growing your business.
          </p>
        </div>
        <div className="our-offers-wrapper">
          {ourOffers.map((offer, index) => (
            <div key={index} className="our-offer-card">
              <span className="our-offer-heading">{offer.heading}</span>
              <img
                src="/images/homepage/arrow_right.svg"
                alt="arrow-right"
                width={24}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center mt-1 pt-5 pb-3">
          <button className="cta-button" onClick={toggle}>
            Enquire Now
            <img src="/images/homepage/arrow_forward.svg" className="" />
          </button>
        </div>
      </section>
      <div className="footer-wrapper--header d-flex align-items-center justify-content-between flex-direction-column-mobile mt-4 custom-container footer-border pb-4 pt-2">
        <div className="icon-logo d-flex  align-items-center gap-md-5 flex-column flex-md-row gap-2">
          <p className="mb-3 mb-md-0">Follow us</p>
          <ul className="d-flex gap-3 flex-wrap">
            <li>
              <a href="https://twitter.com/HealthTunnel" target="_blank">
                <img src={Twitter} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Healthtunnel/" target="_blank">
                <img src={Facebooksvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/healthtunnel_?igsh=YzljYTk1ODg3Zg=="
                target="_blank"
              >
                <img src={Instagramsvg} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCIrAknx2mNOIohWD23cpE4w"
                target="_blank"
              >
                <img src={Youtubesvg} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://in.pinterest.com/HealthTunnel/_created"
              >
                <img src={Pinterest} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/health-tunnel"
              >
                <img src={LinkedinSVG} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Ticon} />
              </a>
            </li>
            <li>
              <a target="_blank" href="http://healthtunnel.tumblr.com/">
                <img src={Thread} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
      <RequestCallbackModal
        isOpen={isOpen}
        toggle={toggle}
        handleSubmit={() => handleSubmitModal()}
        request={request}
        setRequest={setRequest}
        handleSubmitModal={handleSubmitModal}
        simpleValidator={simpleValidator}
        submitLoading={submitLoading}
      />
      {/* <div className="booknow_body bg-white">
        <img src="/images/booknow_home.png" className="mt-4" />
        <p className="book_now_subtext mt-3 text-center">HT SMART CLINICS</p>
        {activeTab === 1 && (
          <>
            <h6 className="mt-3 booknow_header">
              HT SMARTCLINICS- Groundbreaking initiative that aims to transform
              the landscape of healthcare services. This state-of-the-art Smart
              Clinics equipped with innovative Health ATMs covering an
              impressive 64 parameters tests manned trained medical staff.
            </h6>
            <div className="booknow_border mt-4"></div>

            <div className=" text-center mt-4  booknow_learn">
              <h6 className="mx-3 desc">
                To Become a HT Smart Clinic Franchisee
              </h6>
              <button
                onClick={toggle}
                className="cursor-pointer theme-button theme-primary-btn mr-0 mr-sm-3 mb-3 mb-sm-0 display-inline-block border-radius__10 main_button"
              >
                Request Call Back
              </button>
            </div>
            <div className="booknow_border mt-4"></div>
            <p className="mt-3 booknow_subheader">Key Features and Services</p>
            <div className="d-flex flex-wrap mt-3">
              {data.map((item) => {
                return (
                  <div className="d-flex align-items-center data_container">
                    <img
                      src="/images/arrow_forward.svg"
                      className="forward_arrow"
                    />
                    <p className="arrotext">{item}</p>
                  </div>
                );
              })}
            </div>
            <div className="booknow_border mt-4"></div>
            <div className="booknow_learn">
              <h6 className="mx-3 desc">
                To Become a HT Smart Clinic Franchisee
              </h6>
              <button
                onClick={() => setActiveTab(2)}
                className="main_button cursor-pointer theme-button theme-success-btn mr-0 mr-sm-3 mb-3 mb-sm-0 display-inline-block border-radius__10"
              >
                Learn More
              </button>
            </div>
            <div className="booknow_border mt-4"></div>
          </>
        )}
        {activeTab === 2 && (
          <>
            <h6 className="mt-2 booknow_header">
              Healthtunnel, a trailblazer in the medical aggregation business in
              India, proudly presents HT Smart Clinics.
            </h6>
            <p className="desc_text">
              HT Smart Clinics leverage the power of automated health check-up
              kiosks, manned by trained medical staff, to provide comprehensive
              health assessments. These smart clinics stand out with their
              capability to cover an extensive 64 parameters in a single visit,
              ensuring a thorough understanding of an individual’s health
              status.
            </p>
            <div className="booknow_border mt-4"></div>
            <p className="mt-3">Differentiating Factors</p>
            <div className="d-flex flex-wrap mt-3">
              {data1.map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center data_container"
                    key={index}
                  >
                    <img
                      src="/images/arrow_forward.svg"
                      className="forward_arrow"
                    />
                    <p className="arrotext">{item}</p>
                  </div>
                );
              })}
            </div>
            <div className="booknow_border mt-4"></div>
            <h6 className="text-center mt-4 header_text">
              Why Invest in
              <br /> HT Smart Clinics?
            </h6>
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-wrap mt-3 data_container_2_main">
                {data2.map((item, index) => {
                  return (
                    <div className="data_container_2 p-3" key={index}>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={`/images/booknow/booknow_${index}.svg`}
                          className="icon"
                        />
                      </div>
                      <h6 className="header text-center mt-2">
                        {getValue(item, `title`)}
                      </h6>
                      <p className="desc text-center mt-2">
                        {getValue(item, `desc`)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="booknow_border mt-4"></div>
            <h6 className="text-center mt-4 header_text">
              Franchisee Support - Healthtunnel is committed to providing
              <br />
              comprehensive support to its Franchisees, including
            </h6>
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-wrap mt-3">
                {[1, 2, 3].map((item, index) => {
                  return (
                    <div className="data_container_3" key={index}>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={`/images/booknow/image_${index}.svg`}
                          className="icon"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="booknow_border mt-4"></div>
            <div className=" text-center mt-4  booknow_learn">
              <h6 className="mx-3 desc">
                To Become a HT Smart Clinic Franchisee
              </h6>
              <button
                onClick={toggle}
                className="cursor-pointer theme-button theme-primary-btn mr-0 mr-sm-3 mb-3 mb-sm-0 display-inline-block border-radius__10 main_button"
              >
                Request Call Back
              </button>
            </div>
            <div className="booknow_border mt-4"></div>
          </>
        )}
       
      </div> */}
    </>
  );
}

export default HomeBookNow;
