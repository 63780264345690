import graphQLRequest from "../graphQlRequest";
import payForSubscription from "./createPartnerEnquirie";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchCreatePartnerEnquirie(input) {
  const datas = await graphQLRequest(payForSubscription, {
    input: { data: input },
  });
  return datas;
}
