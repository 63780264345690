export default `
mutation KioskOrderPayment($input:KioskPlaceOrderPaymentInput!) { 
    KioskOrderPayment(input:$input){ 
        id
        key
        name
        description
        currency
        amount
        displayAmount
        prefill{
            name
            email
            contact
        }
    }
    }
`;
