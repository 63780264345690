import React, { useEffect, useRef, useState } from "react";
import BookNowStep1 from "./components/Step1";
import BookNowStep2 from "./components/Step2";
import BookNowStep3 from "./components/Step3";
import { fetchCities } from "../../services/cities/fetchCities";
import { getValue } from "../../utils/lodash";
import { fetchLocations } from "../../services/locations/fetchLocations";
import { fetchKiosks } from "../../services/kiosks/fetchKiosks";
import { fetchKiosksTests } from "../../services/kiosksTests/fetchKiosksTests";
import UserBookingModal from "./components/user-modal";
import "./booknow.scss";
import "./landing.scss";
import fetchPayForSubscription from "../../services/KioskOrderPayment/fetchKioskOrderPayment";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import fetchGetTimeSlots from "../../services/getTimeSlots/fetchGetTimeSlots";
import BookingSucessPage from "./components/booking-success";
import axios from "axios";
import { toast } from "react-toastify";

function HealthTunnelBookNow(props) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [filterRequest, setFilterRequest] = useState({
    city_id: "",
    location_id: "",
    kiosk_id: "",
  });

  const [request, setRequest] = useState({
    name: "",
    phone_no: "",
    date: new Date(),
    time: "",
    email: "",
    kiosk: "",
    comment: "",
    kiosk_tests: [],
  });

  const [activeTab, setActiveTab] = useState(1);
  const [paymentInfo, setPaymentInfo] = useState({});
  useEffect(() => {
    getCities();
  }, []);

  const [cities, setCities] = useState([]);
  const getCities = async () => {
    try {
      let resp = await fetchCities(0, 0, {});
      if (resp) {
        setCities(
          getValue(resp, `cities`, []).map((item) => ({
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
            id: getValue(item, `id`, ""),
          }))
        );
        setLocations([]);
        setKiosks([]);
        setKiosksTest([]);
      }
    } catch (error) {}
  };

  const [locations, setLocations] = useState([]);
  const getLocations = async (city_id) => {
    if (city_id) {
      setFilterRequest({
        ...filterRequest,
        city_id: city_id,
      });
      try {
        let payload = {
          city: city_id,
        };
        let resp = await fetchLocations(0, 0, payload);
        if (resp) {
          setLocations(
            getValue(resp, `locations`, []).map((item) => ({
              value: getValue(item, `id`, ""),
              label: getValue(item, `name`, ""),
              id: getValue(item, `id`, ""),
            }))
          );
        }
      } catch (error) {}
    } else {
      setFilterRequest({
        ...filterRequest,
        city_id: "",
        location_id: "",
        kiosk_id: "",
      });
      setKiosks([]);
      setKiosksTest([]);
    }
  };

  const [kiosks, setKiosks] = useState([]);
  const getKiosks = async (location_id) => {
    if (location_id) {
      setFilterRequest({
        ...filterRequest,
        location_id: location_id,
      });
      try {
        let resp = await fetchKiosks(0, 0, { location: location_id });
        if (resp) {
          setKiosks(
            getValue(resp, `kiosks`, []).map((item) => ({
              value: getValue(item, `id`, ""),
              label: getValue(item, `name`, ""),
              id: getValue(item, `id`, ""),
            }))
          );
          setKiosksTest([]);
        }
      } catch (error) {}
    } else {
      setFilterRequest({
        ...filterRequest,
        location_id: "",
        kiosk_id: "",
      });
      setKiosks([]);
      setKiosksTest([]);
    }
  };

  const [kiosksTests, setKiosksTest] = useState([]);
  const [kioskLoading, setKioskLoading] = useState(false);
  const getKiosksTests = async (kiosk_id) => {
    if (kiosk_id) {
      setFilterRequest({
        ...filterRequest,
        kiosk_id: kiosk_id,
      });
      setRequest({
        ...request,
        kiosk: kiosk_id,
      });
      try {
        setKioskLoading(true);
        let resp = await fetchKiosksTests(1000, 0, { kiosks: kiosk_id });
        if (resp) {
          setKiosksTest(getValue(resp, `kioskTests`, []));
          setKioskLoading(false);
        } else {
          setKioskLoading(false);
        }
      } catch (error) {
        setKioskLoading(false);
      }
    } else {
      setFilterRequest({
        ...filterRequest,
        kiosk_id: "",
      });
      setKiosksTest([]);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  function calculateTotalPriceForKioskTests() {
    let totalPrice = 0;
    getValue(request, `kiosk_tests`, []).forEach((kioskTestId) => {
      const test = kiosksTests.find((test) => test.id === kioskTestId);
      if (test && test.discount_price) {
        totalPrice += test.discount_price;
      }
    });
    return totalPrice;
  }

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let payloadRequest = JSON.parse(JSON.stringify(request));
      let payload = {
        input: {
          name: getValue(payloadRequest, `name`, ""),
          phone_no: getValue(payloadRequest, `phone_no`, ""),
          date: moment(getValue(payloadRequest, `date`, "")).format(
            "YYYY-MM-DD"
          ),
          time: getValue(payloadRequest, `time`, ""),
          email: getValue(payloadRequest, `email`, ""),
          kiosk: getValue(payloadRequest, `kiosk`, ""),
          kiosk_tests: getValue(payloadRequest, `kiosk_tests`, []).join(),
        },
      };
      let resp = await fetchPayForSubscription(payload);
      if (resp) {
        setSubmitLoading(false);
        let options = {
          key: getValue(resp, "KioskOrderPayment.key", ""),
          name: getValue(resp, "KioskOrderPayment.name", ""),
          description: getValue(resp, "KioskOrderPayment.description", ""),
          order_id: getValue(resp, "KioskOrderPayment.id", ""),
          prefill: getValue(resp, "KioskOrderPayment.prefill", {}),
          handler: async (response) => {
            setPaymentInfo(response);
            let input = {
              input: {
                data: {
                  signature: getValue(response, "razorpay_signature", ""),
                  payment_id: getValue(response, "razorpay_payment_id", ""),
                  order_id: getValue(response, "razorpay_order_id", ""),
                },
              },
            };
            // if (getValue(response, `razorpay_payment_id`, "")) {
            //   // props.history.push(`/booking-success`)
            // }
            // try {
            //   let verifyResponse = await fetchVerifyPayment(input);
            //   if (verifyResponse) {
            //     console.log(response);
            //     // props.history.push('/dashboard/ht-circle/success');
            //     setActiveTab(4);
            //   }
            // } catch (error) {
            //   console.log(error);
            // }
            try {
              const razorpay_order_id = getValue(
                response,
                "razorpay_order_id",
                ""
              );
              const url = `https://backend.healthtunnel.com/kiosk-payments/order_status/${razorpay_order_id}`;
              const captureResponse = await axios.get(url, {});
              if (getValue(captureResponse, `data.is_completed`, false)) {
                setActiveTab(4);
              } else {
                toast.error("Something went wrong, Please try after sometime");
              }
            } catch (err) {
              console.log(err);
            }
          },
          theme: {
            color: "#686CFD",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        console.log(resp);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleSubmitModal = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setActiveTab(3);
      toggle();
    }
  };

  const getCityName = () => {
    let name =
      getValue(cities, `length`, 0) > 0
        ? cities.find(
            (item) =>
              getValue(item, `id`, "") ===
              getValue(filterRequest, `city_id`, "")
          )
        : {};
    return getValue(name, `label`, "");
  };
  const getLocationName = () => {
    let name =
      getValue(locations, `length`, 0) > 0
        ? locations.find(
            (item) =>
              getValue(item, `id`, "") ===
              getValue(filterRequest, `location_id`, "")
          )
        : {};
    return getValue(name, `label`, "");
  };

  useEffect(() => {
    if (request.date && getValue(filterRequest, `kiosk_id`, "")) {
      getSlots(request.date, getValue(filterRequest, `kiosk_id`, ""));
    }
  }, [getValue(filterRequest, `kiosk_id`, ""), request.date]);

  const [timeSlots, setTimeSlots] = useState([]);
  const getSlots = async (date, id) => {
    try {
      let input = {
        date: moment(date).format("YYYY-MM-DD"),
        kiosk: id,
      };
      let resp = await fetchGetTimeSlots(input);
      if (resp) {
        setTimeSlots(getValue(resp, `getTimeSlots`, []));
        console.log(resp);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-pagev2-wrapper bg-white">
      {getValue(request, `kiosk_tests.length`, 0) > 0 && activeTab == 1 && (
        <div className="kiosk_tests_sticky-header w-100 px-4 d-flex justify-content-between align-items-center">
          <div></div>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="header_text">
              {getValue(request, `kiosk_tests.length`, 0)} Selected {" - "}
              <b className="mx-2">INR {calculateTotalPriceForKioskTests()}</b>
            </h6>
            <div
              className="header-buttons mx-3"
              onClick={() => setActiveTab(2)}
            >
              <a className="theme-button theme-primary-btn cursor-pointer">
                Continue to Proceed
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="custom-container bg-white">
        <div className="custom-container-bookings ">
          {activeTab == 1 &&
            (kioskLoading ? (
              <p className="text-center mt-4">Please wait...</p>
            ) : (
              <BookNowStep1
                cities={cities}
                getLocations={getLocations}
                locations={locations}
                getKiosks={getKiosks}
                kiosks={kiosks}
                getKiosksTests={getKiosksTests}
                kiosksTests={kiosksTests}
                filterRequest={filterRequest}
                setFilterRequest={setFilterRequest}
                request={request}
                setRequest={setRequest}
              />
            ))}
          {activeTab == 2 && (
            <BookNowStep2
              request={request}
              setRequest={setRequest}
              setActiveTab={setActiveTab}
              toggle={toggle}
              timeSlots={timeSlots}
            />
          )}
          {activeTab == 3 && (
            <BookNowStep3
              request={request}
              totalPrice={calculateTotalPriceForKioskTests()}
              kiosksTests={kiosksTests}
              handleSubmit={handleSubmit}
              submitLoading={submitLoading}
              city_name={getCityName}
              location_name={getLocationName}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          )}
          {activeTab == 4 && (
            <BookingSucessPage
              request={request}
              setActiveTab={setActiveTab}
              paymentInfo={paymentInfo}
            />
          )}
        </div>
      </div>
      <UserBookingModal
        isOpen={isOpen}
        toggle={toggle}
        handleSubmit={() => handleSubmitModal()}
        request={request}
        setRequest={setRequest}
        handleSubmitModal={handleSubmitModal}
        simpleValidator={simpleValidator}
      />
    </div>
  );
}

export default HealthTunnelBookNow;
