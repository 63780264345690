import graphQLRequest from "../../services/graphQlRequest";
import dashboardDetails from "./getTimeSlots.js";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchGetTimeSlots(input) {
    const datas = await graphQLRequest(dashboardDetails,{input:input});
    return datas;
}