import React from "react";
import DatePicker from "react-datepicker";
import { getValue } from "../../../utils/lodash";
import Arrowback from "../../../assets/svg/slider/arrowback.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { formatText } from "../../../common/text-helpers";

function BookNowStep2(props) {
  const { request, setRequest, setActiveTab, toggle, timeSlots } = props;

  const handleToggle = () => {
    if (getValue(request, `time`, "")) {
      toggle();
    } else {
      toast.error("Kindly choose a time to proceed.");
    }
  };
  const generateTimeOptions = () => {
    const currentTime = moment();
    const timeOptions = [];
    const startTime = getValue(request, "date", null)
      ? new Date(getValue(request, "date", null))
      : new Date();
    startTime.setHours(0, 0, 0, 0); // Set to midnight

    const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000); // Set end time to 24 hours ahead

    for (
      let time = startTime;
      time < endTime;
      time.setTime(time.getTime() + 10 * 60 * 1000)
    ) {
      const isDisabled = time < currentTime; // Check if time is in the past
      timeOptions.push({
        value: new Date(time), // Create a new date object to avoid reference sharing
        label: time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        disabled: isDisabled, // Add 'disabled' key
      });
    }

    return timeOptions;
  };

  const timeOptions = generateTimeOptions();
  const changeBG = (value, key) => {
    switch (value) {
      case "available":
        return {
          [key]: "#05A357",
        };
      case "busy":
        return {
          [key]: "#EA4335",
        };
      case "booked":
        return {
          [key]: "#1A73E8",
        };
      case "time_expired":
        return {
          [key]: "lighgray",
        };
      default:
        return null;
    }
  };
  return (
    <>
      <div className="mt-4 w-100 px-4 d-flex justify-content-between align-items-center">
        <div onClick={() => setActiveTab(1)}>
          <img src={Arrowback} />
        </div>
        <div></div>
      </div>
      <div className="mt-3">
        <h6 className="header_text mb-2">Select Appointment Date</h6>
        <DatePicker
          selected={
            getValue(request, `date`, null)
              ? getValue(request, `date`, null)
              : new Date()
          }
          onChange={(date) => {
            setRequest({
              ...request,
              date: date,
            });
          }}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          peekNextMonth
          className="appointment-picker"
          // showTimeSelect
          placeholderText="Enter Date of Birth"
          // inline
          dateFormat="dd MMMM yyyy"
          minDate={new Date()}
        />
        <hr />
        <h6 className="header_text mb-3">
          Available Hours for{" "}
          {moment(getValue(request, `date`, null)).format("DD MMM YYYY")}
        </h6>
        {getValue(timeSlots, `length`, 0) > 0 ? (
          <div className="time_options">
            {getValue(timeSlots, `length`, 0) > 0 &&
              timeSlots.map((item) => {
                if (
                  getValue(item, `status`, "") !== "time_expired"
                )
                  return (
                    <div
                      className={`p-2 ${
                        getValue(item, `disabled`, false)
                          ? "time_option_item_disabled"
                          : `time_option_item ${
                              getValue(item, `value`, "") ==
                              getValue(request, `time`, "")
                                ? "active"
                                : ""
                            }`
                      }`}
                      onClick={() =>
                        getValue(item, `status`, "") !== "time_expired" &&
                        getValue(item, `status`, "") !== "busy" &&
                        setRequest({
                          ...request,
                          time: getValue(item, `value`, ""),
                        })
                      }
                    >
                      {getValue(item, `label`, "")}

                      <div className="d-flex align-items-center justify-content-center">
                        <p
                          className="dot mx-1"
                          style={changeBG(
                            getValue(item, `status`, ""),
                            "background"
                          )}
                        ></p>
                        <p
                          className="text-center mx-1 ms-2"
                          style={changeBG(
                            getValue(item, `status`, ""),
                            "color"
                          )}
                        >
                          {formatText(getValue(item, `status`, ""))}
                        </p>
                      </div>
                    </div>
                  );
              })}
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <img src="/images/calendar-nodata.svg" className="mt-4" />
            </div>
            <p className="text-center mt-5 nodata_text">
              No available time slots were found. Please adjust your chosen
              date.
            </p>
          </>
        )}
        <hr />
        <div className="header-buttons mx-3" onClick={handleToggle}>
          <a className="theme-button theme-primary-btn cursor-pointer">
            Continue to Book
          </a>
        </div>
      </div>
    </>
  );
}

export default BookNowStep2;
