
import BookingSucessPage from '../pages/book-now/components/booking-success.jsx';
import HomeBookNow from '../pages/book-now/home.jsx';
import HealthTunnelBookNow from '../pages/book-now/index.jsx';

const routes = [
 
  { path: '/', name: 'labtest', component: HomeBookNow },
  { path: '/home', name: 'labtest', component: HomeBookNow },
  { path: '/atm-book-now', name: 'labtest', component: HealthTunnelBookNow },
  { path: '/booking-success', name: 'labtest', component: BookingSucessPage },

];

export default routes;
