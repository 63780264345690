import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import routes from "./routes/routes";
import Header from "./components/header";

function App() {
  return (
    <>
      <BrowserRouter>
      <Header showMenuItemsCTA={true}/> 
        <Routes>
          {routes.map((routeObj, index) => {
            const Element = routeObj.component;
            return (
              <Route
                key={index}
                path={routeObj.path}
                exact
                element={<Element />}
              />
            );
          })}
          {/* <Route path="/" component={HomepageV2} /> */}
          {/* <Route path="*" component={Pagenotfound} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
