import { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
// import { getLookupAPIs } from "./lookup-apis";
import { getValue } from "../../../utils/lodash";
import CloseSmallSvgComponent from "../../../assets/svg/close-small";

const SingleSelectSearchDropdown = (props) => {
  const { label, id, value, width, onChange, selectKey, data } = props;

  const inputRef = useRef(null);
  const ref = useRef();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const [info, setinfo] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);
  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const handleFetchData = async (search_text) => {
    // if (search_text) {
    //   try {
    //     setIsLoading(true);
    //     let resp = await getLookupAPIs(
    //       getValue(props, `lookup_api`, ""),
    //       getValue(props, `lookup_id`, ""),
    //       search_text
    //     );

    //     if (resp) {
    //       setinfo(resp);
    //       setIsLoading(false);
    //     } else {
    //       setinfo([]);
    //       setIsLoading(false);
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //   }
    // } else {
    //   fetchDefaultData();
    //   onChange(null);
    // }
  };
  const handleFetchDataUponClose = async (search_text) => {
    // try {
    //   setIsLoading(true);
    //   let resp = await getLookupAPIs(
    //     getValue(props, `lookup_api`, ""),
    //     "",
    //     search_text
    //   );
    //   if (resp) {
    //     setinfo(resp);
    //     setIsLoading(false);
    //   } else {
    //     setinfo([]);
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    // }
  };
  // useEffect(() => {
  //   if (getValue(props, `lookup_id`, "")) {
  //     fetchDefaultData();
  //   }
  // }, [getValue(props, `lookup_id`, "")]);
  const fetchDefaultData = async () => {
    // try {
    //   setIsLoading(true);
    //   let resp = await getLookupAPIs(
    //     getValue(props, `lookup_api`, ""),
    //     getValue(props, `lookup_id`, ""),
    //     ""
    //   );
    //   if (resp) {
    //     setinfo(resp);
    //     setIsLoading(false);
    //   } else {
    //     setinfo([]);
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    // }
  };

  const handleClickOption = (option) => {
    onChange && onChange(option);
    setInputChange(false);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    handleFetchDataUponClose("");
    setQuery("");
    onChange(null);
    inputRef.current.focus();
    setIsOpen(true);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const toggleBox = (e) => {
    if (!getValue(props, `disabled`, false)) setIsOpen(!isOpen);
  };
  const toggle = (e) => {
    setIsOpen(e && e.target === inputRef.current);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      //   console.log("clicked outside");
      setIsOpen(false);
    } else {
      //   console.log("click inside");
    }
  };
  /* ---------------------------  API Logics ----------------------------- */
  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedPipeline = (id) => {
    let list =
      getValue(info, `length`, 0) > 0
        ? info.filter(
            (item) =>
              getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
          )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedPipeline(value), [
    value,
    getValue(info, `length`, 0) > 0,
  ]);
  /* ---------------------------  Static Logics ----------------------------- */

  const filter1 = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedValue = (id) => {
    let list =
      getValue(data, `length`, 0) > 0
        ? data.filter(
            (item) =>
              getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
          )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel1 = useMemo(() => findSelectedValue(value), [
    value,
    // getValue(data, `length`, 0) > 0,
  ]);
  return (
    <>
      {getValue(props, `lookup_api`, "") ? (
        <div className={"dropdownselect-form"} ref={ref}>
          {getValue(props, `showLabel`, false) && (
            <label
              className={
                getValue(props, `mandatory`, false)
                  ? "form-label-error"
                  : "form-label"
              }
            >
              {getValue(props, `name`, "")}
              <span className="ms-1">
                {getValue(props, `mandatory`, false) ? "*" : ""}
              </span>
            </label>
          )}

          <div
            className={`selected-value-box position-relative ${
              isOpen ? "active" : ""
            }`}
            onClick={toggleBox} /* add custom width here */
            // style={{
            //   width: getValue(props, `customStyle`, false)
            //     ? getValue(props, `headerWidth`, "")
            //     : width,
            // }}
          >
            <div className="search_container">
              <img src={"/images/search1.svg"} className="search_image" />
              <input
                className="search_input"
                ref={inputRef}
                type="text"
                value={
                  inputChange
                    ? query
                      ? query
                      : valueLabel
                    : valueLabel
                    ? valueLabel
                    : query
                }
                name="searchTerm"
                autoComplete="off"
                placeholder={getValue(
                  props,
                  `placeholder`,
                  "Please Search here..."
                )}
                onChange={(e) => {
                  !getValue(e, `target.value`, "") && onChange(null);
                  setQuery(getValue(e, `target.value`, ""));
                  handleFetchData(getValue(e, `target.value`, ""));
                  setIsOpen(true);
                }}
                onClick={toggleBox}
                disabled={props.disabled}
              />
            </div>
            <div className="right_container" style={{ background: "white" }}>
              {value && !getValue(props, `disabled`, false) && (
                <div onClick={(e) => handleClose(e)}>
                  <CloseSmallSvgComponent />
                </div>
                // <div className="close_image_container">
                //   <img
                //     src={"/images/close.svg"}
                //     className="close_image"
                //     onClick={(e) => handleClose(e)}
                //   />
                // </div>
              )}
              <div className="arrow_image_container">
                <img
                  src={
                    isOpen
                      ? "/dropdown/arrow_up.svg"
                      : "/dropdown/arrow_down.svg"
                  }
                  className="arrow_image"
                />
              </div>
            </div>
          </div>

          <div
            className={`options ${
              isOpen ? "open" : ""
            }`} /* add custom width here */
            style={{
              // width: getValue(props, `customStyle`, false)
              //   ? getValue(props, `optionsWidth`, "")
              //   : width,
              zIndex: 1000,
            }}
          >
            <div>
              <div className="option_container">
                {isLoading ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <img src={`/dropdown/loader2.gif`} className="loader" />
                  </div>
                ) : filter(info).length > 0 ? (
                  filter(info).map((option, index) => {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                          toggle(null);
                        }}
                        className={`option ${
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value
                            ? "selected"
                            : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div className="selected_text">
                          {getValue(option, `[${label}]`, "")}
                        </div>
                        {getValue(
                          option,
                          `[${selectKey ? selectKey : "id"}]`,
                          ""
                        ) == value && (
                          <img
                            src="/dropdown/done.svg"
                            className="done_image"
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="nodata">No info Found</div>
                )}
              </div>
            </div>
            {getValue(props, `add`, "") ? (
              <div className="button_container">
                <img src={"/dropdown/add.svg"} className="button_image" />
                <p className="button_input">Add Button</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={"dropdownselect-form"} ref={ref}>
          {getValue(props, `showLabel`, false) && (
            <label
              className={
                getValue(props, `mandatory`, false)
                  ? "form-label-error"
                  : "form-label"
              }
            >
              {getValue(props, `name`, "")}
              <span className="ms-1">
                {getValue(props, `mandatory`, false) ? "*" : ""}
              </span>
            </label>
          )}
          <div
            className={`selected-value-box position-relative ${
              isOpen ? "active" : ""
            }`}
            onClick={toggleBox} /* add custom width here */
            // style={{
            //   width: getValue(props, `customStyle`, false)
            //     ? getValue(props, `headerWidth`, "")
            //     : width,
            // }}
          >
            <div className="search_container">
              <img src={"/images/search1.svg"} className="search_image" />
              <input
                className="search_input"
                ref={inputRef}
                type="text"
                value={
                  inputChange
                    ? query
                      ? query
                      : valueLabel1
                    : valueLabel1
                    ? valueLabel1
                    : query
                }
                name="searchTerm"
                autoComplete="off"
                placeholder={getValue(
                  props,
                  `placeholder`,
                  "Please Search here..."
                )}
                onChange={(e) => {
                  !e.target.value && onChange(null);
                  setQuery(e.target.value);
                }}
                onClick={toggleBox}
                // style={{
                //   minWidth: getValue(props, `customStyle`, false) && "50px",
                // }}
                disabled={props.disabled}
              />
            </div>
            <div className="right_container" style={{ background: "white" }}>
              {value && !getValue(props, `disabled`, false) && (
                <div onClick={(e) => handleClose(e)}>
                  <CloseSmallSvgComponent />
                </div>
              )}
              <div className="arrow_image_container">
                <img
                  src={
                    isOpen
                      ? "/dropdown/arrow_up.svg"
                      : "/dropdown/arrow_down.svg"
                  }
                  className="arrow_image"
                />
              </div>
            </div>
          </div>

          <div
            className={`options ${
              isOpen ? "open" : ""
            }`} /* add custom width here */
            // style={{
            //   width: getValue(props, `customStyle`, false)
            //     ? getValue(props, `optionsWidth`, "")
            //     : width,
            // }}
          >
            <div>
              <div className="option_container">
                {filter1(data).length > 0 ? (
                  filter1(data).map((option, index) => {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                          toggle(null);
                        }}
                        className={`option ${
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value
                            ? "selected"
                            : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div className="selected_text">
                          {getValue(option, `[${label}]`, "")}
                        </div>
                        {getValue(
                          option,
                          `[${selectKey ? selectKey : "id"}]`,
                          ""
                        ) == value && (
                          <img
                            src="/dropdown/done.svg"
                            className="done_image"
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="nodata">No Data Found</div>
                )}
              </div>
            </div>
            {getValue(props, `add`, "") ? (
              <div className="button_container">
                <img src={"/dropdown/add.svg"} className="button_image" />
                <p className="button_input">Add Button</p>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {props.validator && (
        <span className="form-error">
          {props.validator.current.message(
            getValue(props, `name`, ""),
            props.value,
            "required"
          )}
        </span>
      )}

      {/* {value && getValue(info, `[${0}]policies.length`, 0) > 0 ? (
        <div
          className="mt-2 mb-2 p-2"
          style={{ background: "#fff5f5", borderRadius: "10px" }}
        >
          {getValue(
            info,
            `[${0}].policies[${0}].policy_expense_limit[${0}].expense_amount_limit`,
            ""
          ) && (
            <div className="d-flex px-2">
              <p className="desc_text__14">- Expense Amount Limit</p>
              <p className="desc_text__14 ms-2">
                :{" "}
                {getValue(
                  info,
                  `[${0}].policies[${0}].policy_expense_limit[${0}].expense_amount_limit`,
                  ""
                )}
              </p>
            </div>
          )}
          {getValue(
            info,
            `[${0}].policies[${0}].policy_expense_limit[${0}].receipt_required_limit`,
            ""
          ) ? (
            <div className="d-flex mt-2 px-2">
              <p className="desc_text__14">- Receipt Required Limit</p>
              <p className="desc_text__14 ms-2">
                :{" "}
                {getValue(
                  info,
                  `[${0}].policies[${0}].policy_expense_limit[${0}].receipt_required_limit`,
                  ""
                )}
              </p>
            </div>
          ):null}
        </div>
      ):null} */}
    </>
  );
};

export default SingleSelectSearchDropdown;
