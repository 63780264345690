import React, { useState, useRef, useEffect } from "react";
import { Modal, ModalFooter } from "reactstrap";
import CloseSmallSvgComponent from "../../assets/svg/close-small";
import { getValue } from "../../utils/lodash";
import { createPartnerEnquiries } from "../../services/APIs/common.service";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function RequestCallbackModal(props) {
  const { isOpen, toggle, handleSubmit } = props;

  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState({
    name: "",
    phone: "",
    email: "",
    comments: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitForm = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      try {
        setIsLoading(true);
        let resp = await createPartnerEnquiries({ data: request });
        if (resp) {
          setRequest({
            ...request,
            name: "",
            phone: "",
            email: "",
            comments: "",
          });
          toast.success("Submitted successfully");
          navigate("/");
          setIsLoading(false);
          toggle();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  return (
    <Modal isOpen={isOpen} centered className="user-form-wrapper">
      <div className=" pt-3 px-4 d-flex align-items-center justify-content-between">
        <div>
          <h4 className="">Request Call Back</h4>
        </div>
        <div onClick={toggle}>
          <CloseSmallSvgComponent size={28} />
        </div>
      </div>
      <hr />
      <div className="user_modal_container pb-3">
        <div className="form-group">
          <label htmlFor="full-name">Full Name *</label>
          <input
            type="text"
            value={getValue(request, "name", "")}
            name="name"
            onChange={handleChange}
            id="full-name"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Full Name",
                getValue(request, `name`, ""),
                "required"
              )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="mobile-no">Phone Number *</label>
          <input
            type="number"
            value={getValue(request, "phone", "")}
            name="phone"
            onChange={handleChange}
            id="mobile-no"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Phone Number",
                getValue(request, `phone`, ""),
                "required|integer|size:10"
              )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="email-id">Email Address * </label>
          <input
            type="text"
            value={getValue(request, "email", "")}
            name="email"
            onChange={handleChange}
            id="email-id"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Email Address",
                getValue(request, `email`, ""),
                "required|email"
              )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="email-id">Comments</label>
          <textarea
            value={getValue(request, `comments`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                comments: e.target.value,
              })
            }
            name="comments"
            id="comments"
            rows={5}
            className="form-control profile-form-wrapper__width-input"
          />
        </div>
      </div>
      <ModalFooter>
        <button
          className="cursor-pointer theme-button theme-primary-btn w-100"
          color="blue"
          onClick={handleSubmitForm}
          disabled={props.submitLoading ? true : false}
        >
          {props.submitLoading ? "Please wait..." : "Submit"}
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default RequestCallbackModal;
