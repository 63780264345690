export default `
query getTimeSlots($input:TimeSlotQuery){
  getTimeSlots(input:$input){
    slot
    value
    status
    utc
    booking_id
    label
  }
}
`;