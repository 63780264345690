import React from "react";
import { getValue } from "../../../utils/lodash";
import moment from "moment";
import Arrowback from "../../../assets/svg/slider/arrowback.svg";

function BookNowStep3(props) {
  const {
    totalPrice,
    request,
    kiosksTests,
    handleSubmit,
    submitLoading,
    setActiveTab,
  } = props;

  const getTestInfo = (id) => {
    let info =
      getValue(kiosksTests, `length`, 0) > 0 &&
      kiosksTests.find((item) => getValue(item, `id`, "") === id);
    return {
      name: getValue(info, `name`, ""),
      discount_price: getValue(info, `discount_price`, ""),
    };
  };

  const twelveHourFormat = (militaryTime) =>
    militaryTime ? moment(militaryTime, "HH:mm").format("hh:mm a") : null;
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [props.activeTab]);
  return (
    <div className="review_container p-5">
      <div className="mt-4 w-100 d-flex justify-content-between align-items-center mb-4">
        <div onClick={() => setActiveTab(2)} className="cursor-pointer">
          <img src={Arrowback} />
        </div>
        <div></div>
      </div>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <p>
            Booking
            {/* # 321-353-4118678 (Active) */}
          </p>
          <h4 className="mt-2 mb-2">
            {moment(getValue(request, `date`, "")).format("ddd, DD MMM, YYYY")},{" "}
            {twelveHourFormat(getValue(request, `time`, null))}
          </h4>
          <div className="d-flex align-items-center">
            <p>{props.city_name()}</p>
            <p className="dot mx-2"></p>
            <p>{props.location_name()}</p>
          </div>
        </div>
        <div>
          <p>Total</p>
          <h4 className="mt-2 mb-2">INR {totalPrice}</h4>
        </div>
      </div>
      <hr />
      <div>
        {getValue(request, `kiosk_tests`, []).map((item) => {
          return (
            <div>
              <h6 className="mt-1 mb-1">{getTestInfo(item).name}</h6>
              <p>INR {getTestInfo(item).discount_price}</p>
            </div>
          );
        })}
      </div>
      <hr />
      <div>
        <p className="mt-1">Your Personal Details</p>
        <h4>{getValue(request, `name`, "")}</h4>
        <div className="d-flex align-items-center">
          <p>{getValue(request, `phone_no`, "")}</p>
          <p className="dot mx-2"></p>
          <p>{getValue(request, `email`, "")}</p>
        </div>
      </div>
      <div className="header-buttons mt-4" onClick={handleSubmit}>
        <a className="theme-button theme-primary-btn cursor-pointer">
          {submitLoading ? "Please wait..." : "Continue to Payment"}
        </a>
      </div>
    </div>
  );
}

export default BookNowStep3;
