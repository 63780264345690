import graphQLRequest from "../../services/graphQlRequest";
import payForSubscription from "./KioskOrderPayment";
/**
 *  createEnquire
 *
 * @param {String} null - The shop or id of the properties to fetch
 * @returns {Object}  - the fetched properties
 */
export default async function fetchPayForSubscription(input) {
    const datas = await graphQLRequest(payForSubscription, input);
    return datas;
}