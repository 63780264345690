import React from "react";
import { Modal, ModalFooter } from "reactstrap";
import CloseSmallSvgComponent from "../../../assets/svg/close-small";
import { getValue } from "../../../utils/lodash";

function UserBookingModal(props) {
  const {
    isOpen,
    toggle,
    handleSubmit,
    request,
    handleChange,
    setRequest,
    simpleValidator,
  } = props;
  return (
    <Modal isOpen={isOpen} centered className="user-form-wrapper">
      <div className=" pt-3 px-4 d-flex align-items-center justify-content-between">
        <div>
          <h4 className="header_text">Almost There!</h4>
        </div>
        <div onClick={toggle}>
          <CloseSmallSvgComponent />
        </div>
      </div>
      <hr />
      <div className="user_modal_container pb-3">
        <div className="form-group">
          <label htmlFor="full-name">Full Name *</label>
          <input
            type="text"
            value={getValue(request, `name`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            name="name"
            id="full-name"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Full Name",
                getValue(request, `name`, ""),
                "required"
              )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="mobile-no">Phone Number *</label>
          <input
            type="number"
            value={getValue(request, `phone_no`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                phone_no: e.target.value,
              })
            }
            name="phone_no"
            id="mobile-no"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Phone Number",
                getValue(request, `phone_no`, ""),
                'required|integer|size:10'
                )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="email-id">Email Address *</label>
          <input
            type="text"
            value={getValue(request, `email`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                email: e.target.value,
              })
            }
            name="email"
            id="email-id"
            className="form-control profile-form-wrapper__width-input"
          />
          {
            <span className="form-error">
              {simpleValidator.current.message(
                "Email Address",
                getValue(request, `email`, ""),
                "required|email"
              )}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="email-id">Comments</label>
          <textarea
            value={getValue(request, `comment`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                comment: e.target.value,
              })
            }
            name="comment"
            id="comment"
            rows={5}
            className="form-control profile-form-wrapper__width-input"
          />
        </div>
      </div>
      <ModalFooter>
        <div className="btn-group-margin text-right">
          <button
            className="theme-button theme-primary-btn"
            color="blue"
            onClick={() => {
              handleSubmit();
              // toggle();
            }}
          >
            Continue
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default UserBookingModal;
