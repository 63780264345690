export default `
query kioskTests($limit:Int,$start:Int,$where: JSON){ 
  kioskTests(limit:$limit,start:$start,where:$where){
    id
    name
    description
    original_price
    discount_price
    }
  }
`;
