import React from "react";
import { getValue } from "../../../utils/lodash";

function BookingSucessPage(props) {
  return (
    <div className="succss_page_container">
      <div className="d-flex justify-content-center mt-4">
        <img src="/images/kiosk.svg" />
      </div>
      <div className="succss_page_text">
        <h2 className="text-center">Your Booking Request has been confirmed</h2>
      </div>
      <div className="text-center mt-4 mb-4">
        <p className="text-center color_primary">
          Booking # {getValue(props, `paymentInfo.razorpay_order_id`, "")}
        </p>
      </div>
      <div className="text-center mt-4 mb-4">
        <p>
          The confirmation is been sent to your email address &nbsp;
          <span className="color_primary">
            {getValue(props, `request.email`, "")}
          </span>
        </p>
      </div>
    </div>
  );
}

export default BookingSucessPage;
