import React from "react";
import { getValue } from "../../../utils/lodash";
import SingleSelectSearchDropdown from "../../../components/common/SingleSelectSearch/SingleSelectSearch";

function BookNowStep1(props) {
  const {
    cities,
    getLocations,
    locations,
    getKiosks,
    kiosks,
    getKiosksTests,
    kiosksTests,
    filterRequest,
    request,
    setRequest,
  } = props;

  const concatString = (str, length) => {
    return str.length > (length ? length : 20)
      ? str.substring(0, length ? length : 20).concat("...")
      : str;
  };

  const handleAddKiosks = (id) => {
    let findSelected = getValue(request, `kiosk_tests`, []).filter(
      (item) => item === id
    );
    if (getValue(findSelected, `length`, 0) > 0) {
      let filtered = getValue(request, `kiosk_tests`, []).filter(
        (item) => item !== id
      );
      setRequest({
        ...request,
        kiosk_tests: filtered,
      });
    } else {
      request.kiosk_tests.push(id);
      setRequest({
        ...request,
      });
    }
  };
  return (
    <div className="">
      <div className="kiosk_search_container">
        <div className="item">
          <label>City</label>
          <SingleSelectSearchDropdown
            placeholder={"Select City"}
            name="Select City"
            data={cities}
            label="label"
            onChange={(e) => getLocations(getValue(e, `value`, ""))}
            value={getValue(filterRequest, `city_id`, "")}
          />
        </div>
        <div className="item">
          <label>Location</label>
          <SingleSelectSearchDropdown
            placeholder={"Select Location"}
            name="Select Location"
            data={locations}
            label="label"
            onChange={(e) => getKiosks(getValue(e, `value`, ""))}
            value={getValue(filterRequest, `location_id`, "")}
          />
        </div>
        <div className="item">
          <label>Kiosks</label>
          <SingleSelectSearchDropdown
            placeholder={"Select Kiosks"}
            name="Select Kiosks"
            data={kiosks}
            label="label"
            onChange={(e) => getKiosksTests(getValue(e, `value`, ""))}
            value={getValue(filterRequest, `kiosk_id`, "")}
          />
        </div>
      </div>
      <hr />
      {getValue(kiosksTests, `length`, 0) > 0 ? (
        <div className="kiosk_main_container">
          {getValue(kiosksTests, `length`, 0) > 0 &&
            kiosksTests.map((item) => {
              return (
                <div className="kiosk_container p-4 pb-2 bg-white">
                  <h6 className="header">{getValue(item, `name`, "")}</h6>
                  <div className="d-flex align-items-center mt-2">
                    <p className="amount-linethrough">
                      INR {getValue(item, `original_price`, "")}
                    </p>
                    <p className="mx-3 amount">
                      INR {getValue(item, `discount_price`, "")}
                    </p>
                  </div>
                  <p className="desc mt-2">
                    {concatString(getValue(item, `description`, ""), 400)}
                  </p>
                  <div
                    className={`${
                      getValue(request, `kiosk_tests`, []).includes(
                        getValue(item, `id`, "")
                      )
                        ? "button-container-active"
                        : "button-container"
                    } mt-2`}
                    onClick={() => handleAddKiosks(getValue(item, `id`, ""))}
                  >
                    <div className="mx-1">
                      {getValue(request, `kiosk_tests`, []).includes(
                        getValue(item, `id`, "")
                      ) ? (
                        <img src="/images/done.svg" />
                      ) : (
                        <img src="/images/done.svg" />
                      )}
                    </div>
                    <p>
                      {getValue(request, `kiosk_tests`, []).includes(
                        getValue(item, `id`, "")
                      )
                        ? "Added"
                        : "Add"}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <img src="/images/calendar-nodata.svg" className="mt-4" />
          </div>
          <p className="text-center mt-5 nodata_text">
            To discover available packages for this location, kindly choose the
            city, specify the location, and select a kiosk.
          </p>
        </>
      )}
    </div>
  );
}

export default BookNowStep1;
